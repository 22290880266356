#toc {
  padding: 28px 44px;
  width: 100%;
  overflow: hidden;

  .table-container {
    height: 90%;
    overflow: auto;
  }

  .toc-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 35.5px;
    margin-top: 8px;

    .heading {
      font-family: "Mon-bold";
      font-size: 24px;
      line-height: 29px;
      color: #64666a;
    }
  }
  .filter-section {
    display: flex;
    justify-content: space-between;
    .right-section {
      display: flex;
      align-items: center;

      .search-box-container {
        position: relative;
        .search-box {
          position: absolute;
          right: 0;
          top: 0;
          margin: 0;
          height: 100%;

          &.opacity1 {
            opacity: 1;
            visibility: visible;
            width: 284px;
            z-index: 1;
          }
          &.opacity0 {
            opacity: 0;
            visibility: hidden;
            width: 0px;
            z-index: 0;
          }
        }
      }

      .search-icon-container,
      .filter-icon-container {
        width: 40px;
        height: 40px;
        background: #ffffff;
        border: 1px solid rgba(51, 51, 51, 0.14);
        border-radius: 8px;
        order: 0;
        flex-grow: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .filter-icon-container {
        margin-left: 16px;
      }

      .add-patient {
        width: 168px;
        height: 40px;
        margin-left: 15.59px;
        font-family: "Mon-medium", sans-serif;
        font-size: 14px;
      }
    }
  }
  .toc-presentation-table-filter-appied {
    .common-table-listing-content {
      height: calc(100vh - 19rem) !important;
    }
  }
  .toc-presentation-table {
    height: 100%;
    margin-top: 9px;
    .common-table-listing-header {
      &.scrollbar-added {
        .column:last-child {
          width: 5%;
        }
      }
    }
    .common-table-listing-content {
      height: calc(100vh - 16rem);
      & .column {
        &:first-child {
          color: #00887c;
        }
      }
    }

    & .common-table-listing-header,
    & .common-table-listing-content {
      .column {
        width: 18%;
        &:nth-child(3),
        &:nth-child(5) {
          width: 25%;
        }
        &:nth-child(4) {
          width: 16%;
        }
        &:last-child {
          width: 3.2%;
          padding: 0;
        }
        .content {
          justify-content: start;
        }
        a {
          color: unset;
        }
      }
    }
  }
  .filters-applied {
    display: flex;
  }
  .scroll-component-container {
    height: 85%;
  }
  .toc-table-container {
    margin-top: 10px;
    height: 97%;
    overflow-y: auto;
  }
  .toc-table {
    margin-top: 0;
  }
  .content {
    height: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
