#modal {
  .modal-container {
    z-index: 2;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    transition: all 0.3s ease-in-out;

    &.modal-wrapper-enter-done {
      opacity: 1;
      pointer-events: auto;
      transform: scale(1);
    }
    &.modal-wrapper-exit {
      opacity: 0;
      transform: scale(0.9);
    }

    &.left {
      justify-content: flex-start;
    }
    &.right {
      justify-content: flex-end;
    }
    &.center {
      justify-content: center;
      align-items: center;
    }
    .modal-main {
      background-color: #ffffff;

      .modal-header {
        &-with-patient-details {
          height: 20vh !important;
          border-bottom: 1px solid rgba(151, 151, 151, 0.4);

          .header {
            padding: 16px !important;
            align-items: start;
            [data-for="Close"] {
              padding: 6px;
            }
          }
        }
      }
      .modal-body {
        height: 87.5%;
        &-with-patient-details {
          height: 80vh !important;
        }
      }
    }
  }
}
