@import "../../shared/constant/scss/variables.scss";

.dropdown-container {
  position: relative;
  .select-span {
    height: 40px;
    border: 1px solid #c1c9d2;
    border-radius: 8px;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 11.5px 4.5px 11.5px 12px;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .name-container {
      width: inherit;
      overflow: hidden;
    }
    .selected-value {
      font-family: "Mon-medium";
      color: $grey-text-color;
      font-size: 14px;
      line-height: 17px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .select-box-down-arrow-rotate {
      transform: rotate(180deg);
      transition: transform 0.3s linear;
    }
    .select-box-down-arrow-unrotate {
      transform: rotate(0);
      transition: transform 0.3s linear;
    }
    .icon-container {
      background: $grey-bg-color;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      .select-box-down-arrow {
        cursor: pointer;
        padding: 7px;
      }
    }
  }
  .red-border {
    border: 1px solid #ff5757;
  }
  .error-wrap {
    color: #ff5757;
    font-family: "Mon-medium";
    font-size: 12px;
    margin: 6px 0px;
  }
  .option-box-wrapper {
    border: 1px solid #c1c9d2;
    border-radius: 8px;
    position: absolute;
    top: 42px;
    padding-top: 10px;
    width: 298px;
    background-color: white;
    z-index: 2;
    animation: openDrop 0.2s forwards;

    @keyframes openDrop {
      from {
        opacity: 0.2;
      }
      to {
        opacity: 1;
      }
    }
    ul {
      li {
        cursor: pointer;
        padding: 10px;

        &:hover {
          background-color: $grey-bg-color;
        }

        .selected {
          color: $primary-bg-color;
        }
        .option {
          font-family: "Mon-medium", sans-serif;
          font-size: 14px;
        }
      }
    }
  }
}

.dropdown-container-disabled {
  background: #f0f0f0 !important;
  mix-blend-mode: normal !important;
}
