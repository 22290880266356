@import "shared/constant/scss/variables.scss";

.action-table {
  position: absolute;
  left: 0;
  height: 100%;
  margin-top: 0px;
  .action-row {
    box-shadow: none !important;
    .empty-filling,
    .action-col {
      padding: 8px 46px 8px 22px;
    }
    .action-col {
      text-align: right;
      padding-right: 3%;
      width: 15%;
      background: linear-gradient(
        270deg,
        #c4cdcb 22.29%,
        rgba(238, 238, 238, 0) 100%
      );
      position: relative;
      .edit-action,
      .view-action {
        position: absolute;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        display: flex;
        background: #ffffff;
        align-items: center;
        justify-content: center;
        right: 5%;
        top: 25%;
      }
    }
  }
}
