#patient-episodes {
  padding: 28px 44px;
  width: 100%;
  overflow: hidden;

  .table-container {
    height: 90%;
    overflow: auto;
  }

  .patient-episodes-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 35.5px;

    .heading {
      font-family: "Mon-bold";
      font-size: 24px;
      line-height: 29px;
      color: #64666a;
    }

    .right-section {
      display: flex;
      align-items: center;

      .search-box-container {
        position: relative;
        .search-box {
          position: absolute;
          right: 0;
          top: 0;
          margin: 0;
          height: 100%;

          &.opacity1 {
            opacity: 1;
            visibility: visible;
            width: 284px;
            z-index: 1;
          }
          &.opacity0 {
            opacity: 0;
            visibility: hidden;
            width: 0px;
            z-index: 0;
          }
        }
      }

      .search-icon-container,
      .filter-icon-container {
        width: 40px;
        height: 40px;
        background: #ffffff;
        border: 1px solid rgba(51, 51, 51, 0.14);
        border-radius: 8px;
        order: 0;
        flex-grow: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .filter-icon-container {
        margin-left: 16px;
      }

      .add-episode {
        width: 168px;
        height: 40px;
        margin-left: 15.59px;
        font-family: "Mon-medium", sans-serif;
        font-size: 14px;
      }
    }
  }
  .filters-applied {
    display: flex;
  }
  .scroll-component-container {
    .common-table-listing-content {
      height: calc(100vh - 12rem);
    }
  }
  .scroll-component-container-with-filters {
    .common-table-listing-content {
      height: calc(100vh - 18rem);
    }
  }
  .patient-table {
    margin-top: 0;
    .scrollbar-added {
      .column:last-child {
        width: 16.5%;
      }
    }
    .common-table-listing-content {
      .column:nth-child(7) {
        width: 15%;
      }
    }
  }
  .filters-applied-container {
    .filters-applied-label-container {
      display: flex;
      .filters-applied-label {
        font-family: "Mon-bold";
        font-size: 14px;
        line-height: 17px;
        color: #333333;
        opacity: 0.6;
      }
      .clear-filters-cta {
        font-family: "Mon-medium", sans-serif;
        margin-left: 16px;
        font-weight: 500;
        font-size: 14px;
        color: #1aa99c;
        cursor: pointer;
      }
    }
    .filters-applied-pill-container {
      margin-top: 8px;
      margin-bottom: 24px;
      display: flex;

      .filter-pill {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 8px 8px 12px;
        margin-right: 16px;
        background: #eeeeee;
        border-radius: 8px;
        .filter-pill-content {
          margin-right: 21px;
          .filter-pill-label {
            margin-bottom: 4px;
            font-family: "Mon-bold";
            color: rgba(0, 0, 0, 0.4);
            font-size: 10px;
          }
          .filter-pill-value {
            font-family: "Mon-medium", sans-serif;
            font-size: 12px;
            color: #000000;
          }
        }
        .remove-filter-pill-cta {
          cursor: pointer;
        }
      }
    }
  }
}
