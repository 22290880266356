.search-with-reset-button-container {
  position: relative;
  .reset-button {
    position: absolute;
    right: 4px;
    top: 13px;
    font-family: "Mon-medium", sans-serif;
    font-size: 12px;
    cursor: pointer;
  }
  .loading {
    font-family: "Mon-medium", sans-serif;
    font-size: 12px;
  }
  .option-text {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    .left {
      color: #111;
      font-family: "Mon-medium", sans-serif;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .right {
      color: #636d70;
      font-family: "Mon-medium", sans-serif;
      font-size: 12px;
    }
  }
  div[class$="-singleValue"] {
    .option-text {
      .right {
        display: none;
      }
    }
  }
  span[class$="-A11yText"] {
    display: none;
  }
}
