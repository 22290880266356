.overlay-image-view{
  position: absolute;
  z-index: 99;
  right: 0px;
  top: 0px;
  padding: 10px;
  background-color: rgba(0,0,0,0.5);
  width: 100%;
  display: flex;
  justify-content: end;
  .close-icon{
    color: #ffffff;
    font-size: 20px;
    cursor: pointer;
  }
}

.message-sent-by {
    font-family: "Mon-medium";
    font-size: 12px;
    line-height: 15px;
    color: #636d70;
    margin: 0px 20px;
    opacity: 0.6;
  }
  
  .message {
    margin: 0px 9px;
    display: block;
  
    .right-aligned {
      float: right;
      .media-with-progress {
        background-color: #d9d6bf;
        border-radius: 4px;
        margin-bottom: 6.25px;
        .progress-bar {
          .CircularProgressbar {
            .CircularProgressbar-trail {
              stroke: rgba(0, 0, 0, 0.4);
            }
          }
        }
      }
    }
  
    .message-container {
      background: #f2f0e2;
      border-radius: 4px;
      display: inline-flex;
      flex-direction: column;
      padding: 8px 12px 8px 12px;
      max-width: 75%;
      margin: 11px;
      .image {
        height: 10rem;
        width: 10rem;
        object-fit: cover;
        margin-bottom: 6.25px;
        max-width: 100%;
        cursor: pointer;
      }
      .image-with-progress {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .progress-bar {
          height: 34px;
          width: 34px;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          .cancel-upload {
            position: absolute;
          }
          .retry-upload {
            position: absolute;
          }
        }
      }
      .media-with-progress {
        display: flex;
        padding: 10px;
        align-items: center;
        .media {
          display: flex;
          align-items: flex-start;
          .pdf-icon {
            height: 41.25px;
            margin-right: 11.17px;
          }
          .text {
            font-family: "Mon-semi-bold";
            font-size: 14px;
            line-height: 17px;
            color: #333333;
          }
        }
        .progress-bar {
          height: 34px;
          width: 34px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 27.92px;
          .cancel-upload {
            position: absolute;
          }
          .retry-upload {
            position: absolute;
          }
        }
      }
  
      &.white {
        background-color: white;
        .media-with-progress {
          background-color: #dbe7e5;
          border-radius: 4px;
          margin-bottom: 6.25px;
        }
        border: 1px solid rgba(148, 212, 180, 0.8);
      }
  
      .text {
        font-family: "Mon-medium", sans-serif;
        font-size: 14px;
        line-height: 18px;
        color: #333333;
        margin-bottom: 7px;
        word-break: break-word;
      }
  
      .file-size {
        font-family: "Mon-medium", sans-serif;
        font-size: 12px;
        line-height: 15px;
        color: #333333;
        mix-blend-mode: normal;
        opacity: 0.6;
      }
  
      .timeRow {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
  
        .time {
          font-family: "Mon-medium", sans-serif;
          font-size: 12px;
          line-height: 15px;
          color: #636d70;
        }
  
        .messageSentIcon {
          height: 8px;
          width: 15px;
        }
      }
    }
  }
  