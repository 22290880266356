@import "../../shared/constant/scss/variables.scss";

.checkbox {
  appearance: none;
  width: 16px;
  height: 16px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid rgba(26, 169, 156, 0.5);
  cursor: pointer;
  margin: 0px 8px 0px 0px;

  &:checked {
    background-color: #1AA99C;;
    background-image: url("../../shared/assets/images/checkbox-tick.svg");
    background-repeat: no-repeat;
    height: 16px;
    width: 16px;
    background-origin: border-box;
    background-position: center;
  }
}

.checkbox-label {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: "Mon-medium", sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
  width: 200px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
