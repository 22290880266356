.side-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 2;
  width: 80px;
  &-top {
    display: flex;
    flex-direction: column;
    align-items: center;

    .side-bar-header {
      margin: 25px 0px 21px 0px;
      .logo {
        width: 40px;
        height: 40px;
      }
    }

    .side-bar-body {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .nav-item {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;

        .is-active-nav-item {
          animation: animTab 0.3s ease-in-out forwards;
        }
        a {
          color: #636d70;
          border-radius: 4px;
          width: 100%;
          height: inherit;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .with-bottom-margin {
    margin-bottom: 80px;
  }
}

@keyframes animTab {
  0% {
    background-color: white;
  }
  100% {
    background-color: #f1faf9;
  }
}
