@import "../../../shared/constant/scss/variables.scss";

#modal {
  .filter-side-popup {
    .filter-content-container {
      .filter-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 85%;
        padding: 25px 16px 0px 16px;
        .surgery-sub-heading {
          color: $filter-sub-heading-color;
          font-family: "Mon-semi-bold", sans-serif;
          font-size: 12px;
          line-height: 15px;
          margin-bottom: 6px;
        }
        .surgery-date-to {
          margin-top: 30px;
        }
        .filter-buttons {
          display: flex;
          flex-direction: column;
          margin-bottom: 24px;

          .apply-button {
            margin-bottom: 16px;

            &.disabled {
              opacity: 0.4;
            }
            &.enabled {
              opacity: 1;
            }
          }

          .clear-button {
            &.disabled {
              opacity: 0.4;
              pointer-events: none;
            }
            &.enabled {
              opacity: 1;
            }
          }

          input {
            width: 100%;
            text-transform: none;
          }

          .apply-button,
          .clear-button {
            width: 100%;
            height: 40px;
            font-family: "Mon-medium", sans-serif;
            font-size: 16px;
            line-height: 20px;
          }
        }
      }

      .tag-search-dropdown-container {
        .select-span {
          min-height: 80px;
          max-height: 140px;
          overflow: auto;
          padding: 12px 12px 10px 12px;
          display: flex;
          align-items: start;
          flex-wrap: wrap;
          .search-box-wrapper {
            min-width: 80px;
            height: 32px;
            .search-input {
              width: 100% !important;
              border: none;
              height: 100%;
              font-family: "Mon-medium", sans-serif;
              font-size: 14px;
            }
            .search-input::placeholder {
              font-family: "Mon-medium", sans-serif;
              font-size: 14px;
              line-height: 17px;
              color: $search-color;
              opacity: 0.3;
            }
          }
          .name-container {
            width: 100%;
            .selected-option-tag {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 4px 6px 4px 8px;
              margin: 0 4px 4px 0;
              height: 22px;
              background: #f1faf9;
              max-width: fit-content;
              border-radius: 48px;
              border: 1px solid rgba(148, 212, 180, 1);

              .text {
                font-family: "Mon-medium";
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                color: #333333;
                margin-right: 4px;
              }
              .icon-content {
                display: flex;
                cursor: pointer;
              }
            }
            .search-box-wrapper {
              .search-box {
                &.icon-button {
                  cursor: default;
                  width: 100%;
                  padding: 0;
                }
              }
            }
          }
          .name-container > * {
            flex: 1 1 auto;
          }
        }

        .option-box-wrapper {
          border-radius: 4px;
          width: 100%;
          max-height: 8rem;
          overflow-y: scroll;

          @keyframes openDrop {
            from {
              opacity: 0.2;
            }
            to {
              opacity: 1;
            }
          }
          ul {
            li {
              padding: 10px;
              align-items: center;
              color: #333;
              font-size: 14px;
              display: flex;
              font-family: Mon-medium, sans-serif;
              ß &:hover {
                background-color: $grey-bg-color;
              }
            }
            .no-result-found {
              align-items: center;
              color: #333;
              cursor: auto;
            }
          }
        }
      }
    }

    .mobileView {
      .tag-search-dropdown-container {
        .option-box-wrapper {
          width: 100%;
        }
      }
      .search-box-wrapper {
        width: auto;
        .search-box {
          min-width: 240px;
        }
      }
    }
  }
}
