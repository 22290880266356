#modal {
  .side-popup {
    transform: translateX(100%);
    &.side-popup-animation-enter-done {
      transform: translateX(0%);
      opacity: 1;
    }

    &.side-popup-animation-exit {
      transform: translateX(100%);
      opacity: 0.1;
    }

    &-content {
      width: 300px;
      height: 100vh;

      .header {
        padding: 32px 28px 0px 28px;
        display: flex;
        align-items: baseline;
        margin-bottom: 10px;
        .heading {
          font-family: "Mon-semi-bold", sans-serif;
          font-size: 24px;
          line-height: 29px;
          color: #333333;
          flex: 1;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-left: 22px;
        }
        .close-icon {
          cursor: pointer;
        }
      }
    }
    &-new-message {
      width: 38%;
      .header {
        padding: 32px 28px 0px 28px;
        display: flex;
        align-items: center;
        .heading {
          font-family: "Mon-semi-bold";
          font-size: 24px;
          line-height: 29px;
          color: #333333;
          flex: 1;
          text-align: center;
        }
        .close-icon {
          cursor: pointer;
        }
      }
    }
  }
}
