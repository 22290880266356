@import "../../shared/constant/scss/variables.scss";

.text-input-field-container {
  margin-bottom: 30px;
  font-family: "Mon-medium";

  .field-wrap,
  .field-wrap-disabled {
    position: relative;
    background: #ffffff;
    mix-blend-mode: normal;
    box-sizing: border-box;
    border-radius: 8px;
    width: 300px;
    height: 48px;
    display: flex;
    align-items: center;

    &.black-border {
      border: 1px solid #c1c9d2;
    }

    &.active-border {
      border: 1px solid #5652ff;
      box-shadow: 0px 0px 10px rgba(0, 87, 255, 0.25);
    }
    &:focus {
      border: 1px solid #007aff;
      box-shadow: 0px 0px 10px rgba(0, 87, 255, 0.25);
    }
  }

  .field-wrap-disabled {
    background: #f0f0f0;
    mix-blend-mode: normal;
    opacity: 0.8;
  }
  .focused {
    border: 1px solid #007aff;
    box-shadow: 0px 0px 10px rgba(0, 87, 255, 0.25);
  }
  .info-wrap {
    color: $black-text-color;
    margin-top: 6px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-family: "Mon-medium";
    .info-icon {
      color: $black-text-color;
      margin-right: 5px;
    }
  }
  .error-wrap {
    color: $error-color;
    margin-top: 6px;
    font-size: 12px;
    font-family: "Mon-medium";
  }
  .field-title {
    color: #333333;
    text-transform: capitalize;
    margin-bottom: 7px;
    display: block;
    font-family: "Mon-medium";
    font-size: 14px;
    line-height: 17px;
    margin: 6px 0px;
    .red-color {
      color: red;
    }
  }
  &.password-field {
    margin-bottom: 25px;
    & input {
      -webkit-text-security: disc;
    }
  }

  .red-border {
    border: 1px solid $error-color;
  }
  .eye {
    cursor: pointer;
    padding-right: 14px;
  }
  .input-field {
    color: $grey-text-color;
    border: none;
    margin-left: 12px;
    width: 100%;
    height: 90%;
    border-radius: 8px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    -moz-appearance: textfield;

    &.red-border {
      color: $error-color;
    }

    &::placeholder {
      color: $grey-text-color;
      opacity: 1;
    }
  }
}
