@import "shared/constant/scss/variables.scss";

.toc-row {
  display: flex;
  background: #ffffff;
  padding: 15px;
  font-family: "Mon-medium", sans-serif;
  font-size: 14px;
  align-items: center;
  border-bottom: 1px solid #eef0f6;

  .toc-cell {
    &:nth-child(1) {
      width: 20%;
    }
    &:nth-child(2) {
      width: 20%;
    }
    &:nth-child(3) {
      width: 15%;
    }
    &:nth-child(4) {
      width: 20%;
    }
  }
  .pending-status {
    color: #b91d1d;
  }
  .icons {
    width: 25%;
    display: flex;
    justify-content: end;
    .icon-background {
      margin-right: 16px;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      border-radius: 50%;
      &:hover {
        cursor: pointer;
      }
    }
    .edit-button {
      margin-right: 10px !important;
    }
  }
  &:hover {
    background: #eeeeee;
  }

  &:last-child {
    border-bottom: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.toc-approve-info {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding: 24px 32px;
  background: #eeeeee;
  .toc-content {
    width: 45%;
    .toc-plan {
      .episode-details {
        .toc-plan-heading {
          font-size: 18px;
          font-family: "Mon-bold", sans-serif;
        }

        .episode-details-container {
          margin-top: 20px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .toc-episode-info {
            .toc-plan-episode {
              font-size: 12px;
              font-family: "Mon-bold", sans-serif;
              margin-bottom: 6px;
            }
            .toc-plan-facility {
              font-family: "Mon-medium", sans-serif;
              font-size: 12px;
              color: #636d70;
              margin-bottom: 10px;
            }
          }

          .toc-plan-los-container {
            .acute-los-heading {
              font-family: "Mon-medium", sans-serif;
              font-size: 12px;
              color: #636d70;
              margin-bottom: 6px;
            }

            .acute-los {
              .actue-los-input {
                background: #ffffff;
                mix-blend-mode: normal;
                opacity: 0.8;
                border: 1px solid #c1c9d2;
                border-radius: 8px;
                height: 41.25px;
                text-align: center;
                width: 60px;
              }
            }

            .acute-los-readonly {
              font-family: "Mon-medium", sans-serif;
              font-size: 14px;
              color: #000000;
              display: flex;
              justify-content: end;
            }
          }
        }
      }
      .toc-plan-error {
        color: #ff5757;
        font-family: "Mon-medium", sans-serif;
        font-size: 12px;
        height: 30px;
        span {
          display: flex;
          margin-bottom: 10px;
          img {
            margin-right: 6.5px;
            height: 15px;
            width: 15px;
          }
        }
      }
    }
    .location-grid-container {
      margin-top: 30px;
      .location-dropdowns {
        .location-row-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 17px;
          align-items: center;
          font-size: 12px;
          font-family: "Mon-bold", sans-serif;
          .location {
            width: 70px;
          }

          .location-name {
            width: 100%;
            margin-left: 25px;
            margin-right: 25px;
          }

          .los {
            width: 100px;
            display: flex;
            justify-content: center;
          }
        }
        .location-row {
          display: flex;
          flex-direction: column;
          margin-bottom: 17px;
          .location-content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            align-items: center;
            width: 100%;
            .location-value {
              font-size: 14px;
              color: #636d70;
              font-family: "Mon-medium", sans-serif;
              width: 7rem;
            }
            .toc-dropdown {
              width: 100%;
              margin-left: 25px;
              margin-right: 25px;
            }
            .toc-visits {
              margin-right: 2px;
              .visits-input {
                background: #ffffff;
                mix-blend-mode: normal;
                opacity: 0.8;
                border: 1px solid #c1c9d2;
                border-radius: 8px;
                height: 42px;
                text-align: center;
                width: 60px;

                &:disabled {
                  opacity: 0.5;
                  border: 1px solid #c1c9d2;
                }
              }
              .input-error {
                border: 1px solid #ff5757;
              }
            }
          }
          .location-error {
            width: 100%;
            span {
              margin-top: 9px;
              color: #ff5757;
              font-family: "Mon-medium", sans-serif;
              display: flex;
              align-items: center;
              font-size: 12px;
              img {
                width: 15px;
                margin-right: 7px;
              }
            }
          }
        }
      }
      .checkbox-container {
        width: fit-content;
      }
    }
  }

  .note-container {
    display: flex;
    flex-direction: column;
    width: 45%;
    .navigator-notes,
    .physician-notes {
      .note-heading {
        margin-bottom: 5px;
        font-size: 12px;
        font-family: "Mon-bold", sans-serif;
      }
      .note-textarea-readonly {
        width: 100%;
        max-height: 120px;
        overflow-y: auto;
        font-family: "Mon-medium", sans-serif;
        font-size: 12px;
        color: #333333;
        word-break: break-all;
      }
    }
    .physician-notes {
      display: flex;
      flex-direction: column;
      order: 1;
      width: 100%;
      margin-top: 20px;
      .note-textarea {
        background: #ffffff;
        mix-blend-mode: normal;
        opacity: 0.8;
        border: 1px solid #c1c9d2;
        border-radius: 8px;
        height: 120px;
        resize: none;
        padding: 12px 16px;
        font-family: "Mon-medium", sans-serif;
        color: #333333;
        width: auto;
      }
      .note-hint {
        color: #636d70;
        font-family: "Mon-medium", sans-serif;
        text-align: end;
        font-size: 12px;
        margin-top: 5px;
        .note-limit {
          font-family: "Mon-bold", sans-serif;
        }
      }
    }
  }
}

.toc-approve-info-readonly {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding: 24px 32px;
  background: #eeeeee;
  .note-episode-container {
    min-width: 250px;
    width: 45%;
    margin-top: 20px;
    margin-right: 60px;
    margin-bottom: 20px;
  }
  .note-container {
    display: flex;
    flex-direction: column;
    width: 45%;
    .navigator-notes,
    .physician-notes {
      .note-heading {
        margin-bottom: 5px;
        font-size: 12px;
        font-family: "Mon-bold", sans-serif;
      }
      .note-textarea-readonly {
        width: 100%;
        max-height: 120px;
        overflow-y: auto;
        font-family: "Mon-medium", sans-serif;
        font-size: 12px;
        color: #333333;
        word-break: break-all;
      }
    }
    .physician-notes {
      margin-top: 20px;
    }
  }
  .toc-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 45%;
    .toc-plan {
      .episode-details {
        .toc-plan-heading {
          font-size: 18px;
          font-family: "Mon-bold", sans-serif;
        }

        .episode-details-container {
          margin-top: 20px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .toc-episode-info {
            .toc-plan-episode {
              font-size: 12px;
              font-family: "Mon-bold", sans-serif;
              margin-bottom: 6px;
            }
            .toc-plan-facility {
              font-family: "Mon-medium", sans-serif;
              font-size: 12px;
              color: #636d70;
            }
          }

          .toc-plan-los-container {
            .acute-los-heading {
              font-family: "Mon-medium", sans-serif;
              font-size: 12px;
              color: #636d70;
              margin-bottom: 6px;
            }

            .acute-los {
              .actue-los-input {
                background: #ffffff;
                mix-blend-mode: normal;
                opacity: 0.8;
                border: 1px solid #c1c9d2;
                border-radius: 8px;
                height: 41.25px;
                text-align: center;
                width: 60px;
              }
              .input-error {
                border: 1px solid #ff5757;
              }
            }

            .acute-los-readonly {
              font-family: "Mon-medium", sans-serif;
              font-size: 14px;
              color: #000000;
              display: flex;
              justify-content: end;
            }
          }
        }
      }
      .toc-plan-error {
        color: #ff5757;
        font-family: "Mon-medium", sans-serif;
        font-size: 12px;
        height: 40px;
        span {
          display: flex;
          margin-bottom: 10px;
          img {
            margin-right: 6.5px;
            height: 15px;
            width: 15px;
          }
        }
      }
    }
    .location-grid-container {
      min-width: 250px;
      margin-top: 30px;
      .location-dropdowns {
        .location-row-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 17px;
          align-items: center;
          font-size: 12px;
          font-family: "Mon-bold", sans-serif;
          .location {
            width: 70px;
          }

          .location-name {
            width: 100%;
            margin-left: 25px;
          }

          .los {
            width: 90px;
          }
        }
        .location-row {
          display: flex;
          flex-direction: row;
          margin-bottom: 17px;
          .location-value {
            font-size: 14px;
            color: #636d70;
            font-family: "Mon-medium", sans-serif;
            width: 70px;
          }
          .toc-dropdown-readonly {
            color: #333333;
            font-size: 12px;
            font-family: "Mon-medium", sans-serif;
            margin-left: 25px;
            display: flex;
            width: 100%;
            column-gap: 4px;
          }
          .toc-visits-readonly {
            color: #333333;
            font-size: 12px;
            font-family: "Mon-medium", sans-serif;
            margin-left: 25px;
            display: flex;
          }
        }
      }
      .checkbox-container {
        .checkboxes {
          .checkbox-label {
            cursor: auto;
            .checkbox {
              cursor: auto;
            }
          }
        }
      }
    }
  }
}

.last-row-with-approve-button {
  height: 64px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: end;
  background: #eeeeee;
  padding-right: 32px;

  .cta-container {
    .clear-button,
    .cancel-button {
      font-family: "Mon-medium", sans-serif;
      color: #333333;
      padding: 10px;
      width: 122px;
      height: 40px;
      font-size: 16px;
      cursor: pointer;
      margin: 0px 10px;
      &:disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    .submit-button {
      height: 40px;
      width: 146px;
      font-family: "Mon-medium", sans-serif;
      font-size: 16px;
      line-height: 20px;
      margin-left: 10px;
      cursor: pointer;

      &:disabled {
        pointer-events: none;
      }
    }
  }
}
