.session-timedout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 450px;
  height: 179px;
  background: #FFFFFF;
  border: 1px solid rgba(51, 51, 51, 0.2);
  border-radius: 8px;
  .header{
    font-family: "Mon-bold", sans-serif;
    font-size: 16px;
    padding: 24px 0px;
    color: #333333;
  }
  .content{
    font-family: "Mon-medium", sans-serif;
    font-size: 14px;
    color: #333333;
  }
  .button-section{
    display: flex;
    flex-direction: row;
    margin: 24px 0px;
    .cancel-button{
      font-family: "Mon-medium", sans-serif;
      color: #333333;
      padding: 10px;
      width: 122px;
      height: 40px;
      font-size: 16px;
      cursor: pointer;
      margin-right: 8px;
    }
    .reload-button{
      font-size: 16px;
      width: 122px;
      height: 40px;
      cursor: pointer;
      &:disabled {
        opacity: 0.5;
      }
    }
  }
}
