@import "../../shared/constant/scss/variables.scss";

nav {
  .breadcrumb {
    display: flex;
    .breadcrumb-item {
      font-family: "Mon-semi-bold", sans-serif;
      .name {
        font-family: "Mon-medium", sans-serif;
        text-transform: capitalize;
      }
      .name::before {
        content: "  |  ";
        margin-left: 5px;
        font-family: "Mon-medium", sans-serif;
      }
    }
    .active {
      a {
        color: $primary-bg-color;
        text-transform: capitalize;
      }
    }
    .inactive {
      pointer-events: none;
      a {
        color: $grey-header-color;
      }
    }
    .extra-breadcrumb-info {
      color: $grey-header-color;
      font-family: "Mon-medium", sans-serif;
      text-transform: capitalize;
      margin-left: 3px;
    }
  }
}
