.progress-bar {
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  .cancel-upload {
    position: absolute;
    svg {
      width: 10px !important;
      height: 10px !important;
    }
  }
  .retry-upload {
    position: absolute;
    svg {
      width: 10px !important;
      height: 10px !important;
    }
  }
}

.inactive-infobar-container {
  display: flex;
  padding: 4px 12px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  background: #70a39f;
  color: #fff;

  font-size: 12px;
  font-style: italic;
  line-height: 140%;

  .note-label {
    text-transform: uppercase;
    font-family: "Mon-bold", sans-serif;
    margin-right: 4px;
  }
  .note {
    font-family: "Mon-medium", sans-serif;
  }
}
