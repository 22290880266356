.chat-footer {
  width: 100%;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 0px 6px rgba(193, 199, 204, 0.3);
  border-radius: 2px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  input[type="file"] {
    display: none;
    &.disabled {
      cursor: not-allowed !important;
    }
  }
  .chat-text-container {
    position: relative;
    flex: 6;
    background: #dbe7e5;
    display: flex;
    .attachment-icon-container {
      width: 40px;
      height: auto;

      .chat-attachment-icon {
        height: 100%;
      }
    }
    .attachment {
      position: absolute;
      right: 0.5rem !important;
      top: 1rem !important;
      opacity: 0.5;
      cursor: pointer;
      &.disabled {
        cursor: not-allowed !important;
      }
    }
    .chat-text-box {
      background: #dbe7e5;
      border: 1px solid #e5ede7;
      border-radius: 4px;
      height: 42px;
      width: 94%;
      outline: none;
      padding: 8px 0px 0px 8px;
      font-family: "Mon-medium", sans-serif;
      font-size: 14px;
      max-height: 60px;
      overflow-x: hidden;
      overflow-y: auto;
      resize: none;

      &:disabled {
        cursor: not-allowed;
      }
    }
  }
  .send {
    flex: 1;
    display: flex;
    justify-content: center;

    &.disabled {
      cursor: not-allowed !important;
    }
    &.enabled {
      cursor: pointer;
    }
  }
}
