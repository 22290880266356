@import "../../shared/constant/scss/variables.scss";

.tag-search-dropdown-container {
  position: relative;
  width: 100%;
  z-index: 0;
  .no-error {
    border: 1px solid $primary-bg-color;
  }
  .error {
    border: 1px solid $red-notification-color;
  }
  .error-color {
    color: $red-notification-color;
    margin-top: 6px;
    font-size: 12px;
    font-family: "Mon-medium";
  }
  .select-span {
    height: auto;
    border: 1px solid #c1c9d2;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 6.75px;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .search-box-wrapper {
      display: flex;
      align-items: center;
      height: 40px;

      min-width: 160px;
      .search-box {
        border: none;
        margin-right: 0;
        width: 0;
        min-width: 150px;
        &.icon-button > .input-plus-lens {
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 100%;
          input {
            text-transform: none;
          }
        }
      }
    }
    .name-container {
      display: flex;
      width: inherit;
      overflow: unset;
      flex-wrap: wrap;
      .selected-option-tag {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 6px;
        margin: 4px;

        height: 22px;
        background: #f1faf9;

        border-radius: 48px;
        border: 1px solid rgba(148, 212, 180, 1);

        .text {
          font-family: "Mon-medium";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: #333333;
          margin-right: 8px;
        }
        .icon-content {
          display: flex;
          cursor: pointer;
        }
      }
    }
    .selected-value {
      font-family: "Mon-medium";
      color: $grey-text-color;
      font-size: 14px;
      line-height: 17px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .placeholder {
      color: #999999;
    }

    .select-box-down-arrow-rotate {
      transform: rotate(180deg);
      transition: transform 0.3s linear;
    }
    .select-box-down-arrow-unrotate {
      transform: rotate(0);
      transition: transform 0.3s linear;
    }
    .icon-container {
      background: $grey-bg-color;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      .select-box-down-arrow {
        padding: 7px;
      }
    }
  }
  .red-border {
    border: 1px solid #ff5757;
  }
  .error-wrap {
    color: #ff5757;
    font-family: "Mon-medium";
    font-size: 14px;
    margin: 6px 0;
  }
  .options-box {
    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .option {
        font-family: "Mon-medium", sans-serif;
        line-height: 100%;
        &.option-right {
          color: #636d70;
          font-size: 12px;
        }

        &.option-left {
          color: #111;
          font-size: 14px;
        }
      }
      &.disabled {
        cursor: not-allowed;
        .option {
          color: #636d70;
          font-family: "Mon-medium", sans-serif;
          font-size: 14px;
          line-height: 100%;
        }
        p {
          span {
            color: #636d70;
            font-family: "Mon-medium", sans-serif;
            font-size: 1.4px;
            line-height: 100%;
          }
        }
      }
    }
  }

  .option-box-wrapper {
    left: 0;
    border: 1px solid #c1c9d2;
    border-radius: 8px;
    position: absolute;
    top: 100%;
    padding-top: 0px;
    width: 298px;
    background-color: white;
    z-index: 3;
    animation: openDrop 0.2s forwards;
    max-height: 240px;
    overflow-y: auto;
    @keyframes openDrop {
      from {
        opacity: 0.2;
      }
      to {
        opacity: 1;
      }
    }
    ul {
      overflow: auto;

      li {
        cursor: pointer;
        padding: 16px;
        &:hover {
          background-color: #f1faf9;
        }

        .selected {
          color: $primary-bg-color;
        }
        .option {
          font-family: "Mon-medium", sans-serif;
          font-size: 14px;
        }
      }
      .no-result-found {
        font-family: "Mon-medium", sans-serif;
        font-size: 14px;
        padding: 10px;
      }
    }
  }
}
