@import "shared/constant/scss/variables.scss";

.messages-table {
  thead {
    margin-bottom: 10px;
    position: sticky;
    top: 0;
    left: 0;
    background: #f7fcfb;
    z-index: 1;
    &::after {
      content: "";
      height: 1px;
      width: 100%;
      margin-left: 0.2rem;
      position: absolute;
      left: 0;
      top: 0;
      background: $primary-border-color;
    }
    tr {
      font-weight: bold;
      background: rgba(241, 250, 249, 0.6);
      border-radius: 8px 8px 0px 0px;
      border-style: hidden;
      box-shadow: 0 0 0 1px $primary-border-color;
      th {
        text-align: left;
        font-size: 14px;
        padding: 15px;
        border: 1px solid $primary-border-color;
        border-style: solid none solid none;
        font-family: "Mon-bold", sans-serif;

        .messages-table-header-content {
          display: flex;

          .name {
            margin-right: 9px;
          }
          &-sort {
            width: fit-content;
            cursor: pointer;
          }
        }
      }
      th:nth-child(1) {
        text-align: left;
        border-left: 1px solid $primary-border-color;
        border-radius: 8px 0px 0px 0px;
      }
      th:nth-child(3) {
        text-align: left;
      }
      th:last-child {
        border-style: solid solid solid none;
      }
    }
  }
  tbody {
    .no-message-container {
      position: relative;
      .no-message-td {
        height: 50px;
        border-style: none;
      }
      .no-messages {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        font-family: "Mon-medium", sans-serif;
        font-size: 14px;
      }
    }
    tr {
      td {
        text-align: left;
        font-size: 14px;
        line-height: 16px;
        padding: 15px;
        border: 1px solid $grey-border-color;
        border-style: solid none solid none;
        font-family: "Mon-medium", sans-serif;
        a {
          color: $primary-text-color;
          cursor: pointer;
        }
        span {
          .bold-text {
            font-weight: bold;
            color: #1aa99c;
          }
        }
      }
      td:nth-child(1) {
        text-align: left;
        border-style: solid none solid solid;
        width: 20%;
      }
      td:nth-child(2) {
        width: 20%;
        text-align: left;
        text-transform: capitalize;
      }
      td:nth-child(3) {
        width: 25%;
      }
      td:nth-child(4) {
        text-align: left;
        width: 35%;
        position: relative;
        .second-last-column {
          display: flex;
          align-items: center;
          &-content {
            margin-left: 24.62px;
            white-space: nowrap;
            word-break: break-word;
            overflow: hidden;
            width: 80%;
            text-overflow: ellipsis;
            position: absolute;
          }
        }
      }
      td:nth-child(5) {
        text-align: left;
        text-decoration: underline;
        border-style: solid none;
        .second-last-column {
          display: flex;
          align-items: center;
          &-content {
            margin-left: 24.62px;
          }
        }
      }
      td:last-child {
        border-style: solid solid solid none;
        .last-column {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          &.visible {
            opacity: 1;
            transition: opacity 0.2s ease-in-out;
            padding-right: 10px;
            .table-icon {
              cursor: pointer;
            }
            .table-icon-disabled {
              opacity: 0.5;
            }
          }

          &.hidden {
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
            padding-right: 10px;
          }

          .table-icon {
            width: 32px;
            height: 32px;
            background: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
          }
          .table-icon-disabled {
            opacity: 0.5;
          }
        }
      }
    }
    tr:last-child {
      border-radius: 0px 0px 8px 8px;
      border-style: hidden;
      box-shadow: 0 0 0 1px $grey-border-color;
    }
    .hovered {
      background: #eeeeee;
      cursor: pointer;
    }
  }
}
