@import "../../../shared/constant/scss/variables.scss";

.footer-button-section{
  display: flex;
  flex-direction: row;
	justify-content: center;
	input{
		margin: 0px 10px;
	}
  .clear-button{
    width: 122px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #ACC0BB;
    border-radius: 8px;
	cursor: pointer;
  }
	.next-button{
		width: 121.79px;
		height: 40px;
		background: #1AA99C;
		border-radius: 8px;
		border: none;
		color: white;
		cursor: pointer;
		margin: 0px 10px;
		&:disabled{
			background: #a3ddd7;
			cursor: auto;
		}
	}
}
