.dashboard-button-container {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  background: linear-gradient(#bcdfef 100%, #94d4b4 100%);
  width: 100%;
  margin: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &-inner {
    width: 99%;
    height: 99%;
    background: rgb(255, 255, 255);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 6px;
    cursor: pointer;

    img {
      margin-top: 20px;
    }
  }

  .button-text {
    font-family: "Mon-bold", sans-serif;
    font-size: 18px;
    line-height: 22px;
    color: rgba(51, 51, 51, 0.8);
    margin-top: 21px;
    margin-bottom: 30px;
  }
}
