.line-container {
    border-bottom: 2px solid rgba(167,221,206, 0.4);
    position: relative;
    display: flex;
    justify-content: center;
    margin: 15px 20px 12px 20px;
    z-index: 1;
  
    .time {
      position: absolute;
      top: -7px;
      background-color: white;
      font-family: "Mon-medium", sans-serif;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
    }
  }
  