.compose-message-popup-container {
  .modal-footer {
    .create-conversation-button {
      width: -webkit-fill-available;
      padding: 10px;
      margin: 0 20px 20px 20px;
      margin-top: 70%;
    }
  }
  .row {
    margin: 16px 0px 16px 0px;
    &.disabled-row {
      opacity: 0.5;
      pointer-events: none;
    }
    .recipient-text {
      color: #333;
      font-family: "Mon-medium", sans-serif;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 20px;
    }
    .single-select-dropdown-with-search {
      margin: 0;
      .select__control {
        border: 1px solid #acc0bb;
        &:focus {
          border: 1px solid #acc0bb !important;
        }
        &:hover {
          border: 1px solid #acc0bb !important;
        }
      }
    }
    .tag-search-dropdown-container {
      width: auto;
      .option-box-wrapper {
        top: 104%;
        & > .options-box {
          & > .table-loader {
            padding: 10px 0;
          }
          .bold-text {
            font-weight: bold;
            color: #1aa99c;
          }
        }
      }
      .select-span {
        padding: 0;
        .search-box-wrapper .search-box {
          cursor: default;
          &.icon-button > .input-plus-lens input {
            z-index: 0;
          }
        }
      }
      .no-pointer-events {
        opacity: 1;
      }
    }
    .dropdown-container {
      .option {
        font-size: 12px;
      }
      .navigator-box,
      .subject-box {
        .no-result-found {
          font-family: "Mon-medium", sans-serif;
          font-size: 12px;
          padding: 10px;
        }
      }
      .search-box {
        width: 100%;
        margin: 0;
      }
    }
    label {
      margin-bottom: 10px;
      color: #333;
      font-family: "Mon-semi-bold", sans-serif;
      font-size: 12px;
      line-height: 100%;
      display: block;
    }

    .recipient-and-patient {
      display: grid;
      grid-template-columns: 15% 1fr;
      margin-bottom: 16px;
      .recipient-dropdown-container {
        margin-left: 10px;
        .selected-navigators-count {
          color: #636d70;
          font-family: "Mon-medium", sans-serif;
          text-align: end;
          font-size: 12px;
          margin-top: 5px;
          span {
            font-family: "Mon-bold", sans-serif;
          }
        }
      }

      .tag-search-dropdown-container {
        .option-box-wrapper {
          width: 100%;
          ul li {
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          }
        }
        .search-lens-icon {
          width: 18px !important;
          margin-right: 12px;
          transform: translateY(1px);
        }
      }
    }

    .patient-checkbox {
      &.disabled {
        .checkbox-label {
          cursor: not-allowed;
        }
      }
      .checkbox-label {
        color: #333;
        font-family: "Mon-medium", sans-serif;
        font-size: 12px;
        line-height: 100%;
        display: grid;
        grid-template-columns: 31% 1fr;
        text-align: right;
        align-items: center;

        &.disabled {
          cursor: not-allowed;
        }

        #patient {
          border-radius: 2px;
          border: 1px solid #bcdfef;
          width: 16px;
          height: 16px;
          display: flex;
          flex-grow: 0;
          flex-shrink: 0;
          grid-column: 2;
          grid-row: 1;
          margin-left: 15px;
          &:disabled {
            cursor: not-allowed;
          }
        }
      }
    }

    .note {
      color: #636d70;
      font-family: "Mon-medium", sans-serif;
      font-size: 12px;
      font-style: italic;
      line-height: 100%;
    }
  }
}
