// @import 'src/app/common/utils/scss/main.scss';

.loginScreenWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  height: 100%;

  .leftLoginBlock {
    width: 40%;
    height: 100%;
    position: relative;

    .logoHeader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 42px;
      .logo {
        width: 40px;
        height: 40px;
      }
    }
    .parentContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }

  .rightImageBlock {
    width: 60%;
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: inherit;
    }
    .big-text {
      position: absolute;
      bottom: 80px;
      z-index: 2;
      .line1 {
        font-family: "Mon-light";
        font-size: 34px;
        line-height: 41px;
        letter-spacing: 0.08em;
        color: #e5e5e5;
      }
      .line2 {
        text-align: center;
        font-family: "Mon-bold";
        font-size: 63px;
        line-height: 82px;
        letter-spacing: 0.08em;
        color: #e5e5e5;
      }
    }

    .login-screen-image {
      background-size: 100%;
    }
  }
}
