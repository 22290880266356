.table-loader{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 3px 0px;
	.loading-text{
		margin-left: 14px;
		font-family: 'Mon-medium', sans-serif;
		font-size: 14px;
		color: #868686;
	}
}
