#messages {
  padding: 28px 44px;
  width: 100%;
  overflow: hidden;

  .table-container {
    height: 90%;
  }

  .messages-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 35.5px;

    .heading {
      font-family: "Mon-bold";
      font-size: 24px;
      line-height: 29px;
      color: #64666a;
    }

    .right-section {
      display: flex;
      align-items: center;

      .search-box-container {
        position: relative;

        .search-box {
          position: absolute;
          right: 0;
          top: 0;
          margin: 0;
          height: 100%;

          &.opacity1 {
            opacity: 1;
            visibility: visible;
            width: 395px;
            z-index: 1;
          }

          &.opacity0 {
            opacity: 0;
            visibility: hidden;
            width: 0px;
            z-index: 0;
          }
        }
      }

      .search-icon-container,
      .filter-icon-container {
        width: 40px;
        height: 40px;
        background: #ffffff;
        border: 1px solid rgba(51, 51, 51, 0.14);
        border-radius: 8px;
        order: 0;
        flex-grow: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .filter-icon-container {
        margin-left: 16px;
      }
    }
  }

  .add-patient {
    width: 168px;
    height: 40px;
    margin-left: 15.59px;
    font-family: "Mon-medium", sans-serif;
    font-size: 14px;
  }

  .filters-applied {
    display: flex;
  }

  .messages-table {
    margin-top: 0;
    .common-table-listing-content {
      height: calc(100vh - 12rem);
    }
    .common-table-listing-header {
      &.scrollbar-added .column:last-child {
        width: 6.5%;
      }
    }
    .common-table-listing-header,
    .common-table-listing-content {
      .column {
        box-sizing: border-box;
        padding: 15px;
        width: 20%;
        display: flex;
        align-items: center;
        .content {
          justify-content: start;
        }
        .subject-icon {
          display: flex;
          align-items: center;
          margin-left: 10px;
        }
        &:nth-child(2) {
          .type-container {
            width: fit-content;
            background-color: #ccecf5;
            padding: 2px 12px;
            border-radius: 9px;
            .type-text {
              text-align: center;
              color: #667a78;
              font-family: "Mon-medium", sans-serif;
              font-size: 14px;
            }
          }
        }
        &:nth-child(4) {
          width: 35%;
          .second-last-column {
            display: flex;
            align-items: center;
            &-content {
              margin-left: 24.62px;
              white-space: nowrap;
              word-break: break-word;
              overflow: hidden;
              width: 80%;
              text-overflow: ellipsis;
              position: absolute;
            }
          }
        }
        &:last-child {
          width: 5%;
          .last-column {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            &.visible {
              opacity: 1;
              transition: opacity 0.2s ease-in-out;
              padding-right: 10px;
              .table-icon {
                cursor: pointer;
              }
              .table-icon-disabled {
                opacity: 0.5;
              }
            }

            &.hidden {
              opacity: 0;
              transition: opacity 0.2s ease-in-out;
              padding-right: 10px;
            }

            .table-icon {
              width: 32px;
              height: 32px;
              background: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
            }
            .table-icon-disabled {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  .content {
    height: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

#modal {
  .side-popup-content {
    width: 500px;
  }

  .chat-side-popup {
    .modal-header {
      height: 8%;
    }

    .chat-content {
      height: 92% !important;
      position: relative;

      &.compose {
        height: 88% !important;
      }

      .chat-messages {
        &.height {
          height: 88% !important;
        }

        &.compose {
          height: 81% !important;
        }

        &.reduce-height {
          height: 76% !important;
        }
      }

      #chat-messages {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 8px;
      }

      .preview-section {
        &.hidden {
          display: none;
          opacity: 0;
        }

        &.show {
          display: flex;
          opacity: 1;
          width: 100%;
          bottom: 76px;
          position: absolute;
          background: #dbe7e5;
          align-items: center;
        }
      }

      .chat-footer {
        position: absolute;
        bottom: 0px;
        width: 100%;
        background: #ffffff;
        border: 1px solid #e6e6e6;
        box-shadow: 0px 0px 6px rgba(193, 199, 204, 0.3);
        border-radius: 2px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        input[type="file"] {
          display: none;
        }

        label {
          cursor: pointer;
        }

        .chat-text-container {
          position: relative;
          width: 92%;
          background: #dbe7e5;

          .attachment {
            position: absolute;
            right: -10px;
            top: 5px;
            opacity: 0.5;
          }

          .chat-text-box {
            background: #dbe7e5;
            border: 1px solid #e5ede7;
            border-radius: 4px;
            height: 42px;
            width: 95%;
            outline: none;
            padding-left: 10px;
          }
        }

        .send {
          width: 8%;
          align-self: center;
          margin: 15px 20px 15px 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
  }
}
