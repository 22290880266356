@import "../../../shared/constant/scss/variables.scss";
.loginFormWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transition: transform 0.5s ease-in-out;

  .login-form {
    display: flex;
    flex-direction: column;

    .login-field-wrapper {
      transform: translateY(-75px);
      .fieldLabel {
        font-family: "Mon-medium", sans-serif;
        font-size: 15px;
        line-height: 130%;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.42);
        margin-bottom: 2px;
        padding-left: 2px;
      }

      .username-icon {
        margin-left: 14.25px;
        margin-right: 12.25px;
      }

      .login-input-container {
        width: 380px !important;
      }
      .input-field {
        width: 100%;
        background-color: transparent;
        font-size: 14px;
        line-height: 115%;
        font-family: "Mon-semi-bold";
        border-style: solid;
        border: none;
        &::placeholder {
          color:rgba(126,126,126, 0.3);
        }
      }
    }

    .login-button-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      transform: translateY(-75px);
      justify-content: space-between;
      .forgot-password {
        font-size: 16px;
        line-height: 20px;
        font-family: "Mon-medium", sans-serif;
      }
      .login-button-container {
        .login-button {
          background-color: $primary-bg-color;
          color: white;
          font-family: "Mon-medium", sans-serif;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 111px;
          height: 40px;
          border-radius: 8px;
          border: none;
          font-size: 16px;
          cursor: pointer;
        }

        .disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }
  }
}

.loginFormWrapperTransform {
  transform: translateX(-150%);
}
