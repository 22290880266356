#dashboard {
  padding: 28px 44px;
  width: 100%;
  overflow: hidden;
  .dashboard-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 35.5px;
    font-family: "Mon-bold", sans-serif;
    font-size: 24px;
    line-height: 29px;
    color: #64666a;
  }
  .dashboard-buttons {
    display: grid;
    grid-template-columns: 30% 30% 30% 1fr;
    grid-template-rows: 1fr;
    gap: 5%;
  }
}
