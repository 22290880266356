.tab-container {
  display: flex;
  .tabs {
    display: flex;
    .tab {
      padding: 11.5px 24px;
      font-size: 16px;
      color: #606060;
      border-top: 1px solid #eef0f6;
      border-bottom: 1px solid #eef0f6;
      font-family: "Mon-semi-bold", sans-serif;
      font-size: 14px;
      cursor: pointer;
    }
    .tab:first-child {
      border-left: 1px solid #eef0f6;
      border-radius: 8px 0px 0px 8px;
    }
    .tab:last-child {
      border-right: 1px solid #eef0f6;
      border-radius: 0px 8px 8px 0px;
    }
    .active {
      background: #1aa99c;
      color: white;
      border-top: 1px solid #1aa99c;
      border-bottom: 1px solid #1aa99c;
    }
  }
}
