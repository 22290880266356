@import "../../../shared/constant/scss/variables.scss";

#modal {
  .compose-side-popup {
    .modal-header {
      height: 12vh;
      .header {
        padding: 32px 28px 0;
        align-items: center;
        svg {
          width: 20px !important;
          height: 20px !important;
        }
      }
    }
    .modal-body {
      height: 88vh;
    }
    .chat-content {
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;

      &.compose {
        .loading-conversation {
          margin-bottom: 150px;
        }
      }

      .chat-messages {
        &.height {
          height: 87.5% !important;
        }

        &.height-with-infobar {
          height: -webkit-fill-available !important;
        }

        &.inactive-height {
          height: 92.5% !important;
        }

        &.compose {
          height: 78% !important;
        }

        &.reduce-height {
          height: 76% !important;
        }
      }

      #chat-messages {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        overflow-y: auto;
        overflow-x: hidden;
        // margin-top: 8px;
      }

      .preview-section {
        &.hidden {
          display: none;
          opacity: 0;
        }

        &.show {
          display: flex;
          opacity: 1;
          width: 100%;
          bottom: 76px;
          position: absolute;
          background: #dbe7e5;
          align-items: center;
        }
      }

      .chat-footer {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        background: #ffffff;
        border: 1px solid #e6e6e6;
        box-shadow: 0px 0px 6px rgba(193, 199, 204, 0.3);
        border-radius: 2px;
        padding: 7px 10px 5px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        input[type="file"] {
          display: none;
        }

        label {
          cursor: pointer;
        }

        .chat-text-container {
          position: relative;
          width: 92%;
          background: #dbe7e5;
          border-radius: 4px;
          .attachment {
            position: absolute;
            right: -10px;
            top: 5px;
            opacity: 0.5;
          }

          .chat-text-box {
            background: #dbe7e5;
            border: 1px solid #e5ede7;
            border-radius: 4px;
            height: 42px;
            width: 95%;
            outline: none;
            padding-left: 10px;
          }
        }

        .send {
          width: 8%;
          align-self: center;
          // margin: 15px 20px 15px 10px;
          margin: 15px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }

    .compose-message-section {
      display: flex;
      flex-direction: column;
      position: relative;
      margin: 0px 32px 0px 28px;

      .add-recipient-search-section {
        display: flex;
        flex-direction: row;
        height: 40px;
        border: 1px solid #a6d9ce;
        border-radius: 8px;

        .input-field-container {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin: 5px 5px 5px 12px;

          .input-fields {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .add-recipient-input {
              border: none;
              font-size: 24px;
              outline: none;
              font-family: "Mon-medium", sans-serif;
              font-size: 15px;
              margin-left: 15.45px;
            }
          }

          .selected-dropdown-section {
            .selected-dropdown-button {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              background: $light-green-color-2;
              border-radius: 4px;
              padding: 4px 7px 5px 13px;
              font-family: "Mon-medium", sans-serif;
              font-size: 15px;
              line-height: 22px;

              .down-arrow-icon {
                padding: 4px;
              }

              .up-arrow-icon {
                padding: 4px;
              }
            }

            .grey-background {
              background: $light-green-color-2;
            }
          }
        }
      }

      .selected-dropdown {
        position: absolute;
        width: 100%;
        top: 45px;
        max-height: 320px;
        overflow-y: auto;
        z-index: 2;
        background: #dbe7e5;
        border-radius: 12px;

        .dropdown-single-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 22px 24px;

          .detail-section {
            font-family: "Mon-medium", sans-serif;
            font-size: 14px;
          }
        }

        .dropdown-single-item:not(:last-child) {
          border-bottom: 1px solid #cad5d3;
        }
      }

      .search-results-section {
        position: absolute;
        top: 45px;
        width: 100%;
        max-height: 320px;
        overflow-y: auto;
        z-index: 2;
        background: #dbe7e5;
        border-radius: 12px;

        .empty-state-container {
          height: 61px;
        }
        .loader img {
          width: 50px;
        }

        .search-items {
          display: flex;
          flex-direction: row;
          padding: 22px 24px;
          font-family: "Mon-medium", sans-serif;
          font-size: 14px;
          cursor: pointer;

          .highlighted-text-section {
            display: flex;
            flex-direction: row;
          }

          .bold-text {
            font-weight: bold;
            color: #1aa99c;
          }
        }

        .search-items:not(:last-child) {
          border-bottom: 1px solid #cad5d3;
        }
      }
    }
  }
  .footer-container {
    position: relative;
    // bottom: 0px;
    width: 100%;
    z-index: 2;
    left: 0px;
    box-sizing: content-box;
    height: 87px;
    .next-button {
      width: 90%;
      color: #fff;
      font-family: "Mon-medium", sans-serif;
      font-size: 16px;
      border-radius: 8px;
      margin: 0px 0px 15px 24px;
      padding: 10px 30px;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    .progress-bar {
      height: 34px;
      width: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px;

      .cancel-upload {
        position: absolute;
        svg {
          width: 10px !important;
          height: 10px !important;
        }
      }

      .retry-upload {
        position: absolute;
        svg {
          width: 10px !important;
          height: 10px !important;
        }
      }
    }
  }
  .two-liner-heading {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .participants {
      font-family: "Mon-bold", sans-serif;
      font-size: 18px;
      line-height: normal;
      color: #333333;
      flex: 1;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 20px;
      cursor: default;
      &-with-patient {
        text-align: start;
        margin-left: 0;
      }
    }
    .subject {
      color: #636d70;
      font-family: "Mon-medium", sans-serif;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      &-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
        margin: 0 2px;
        cursor: default;
      }
    }

    .patient-details {
      display: flex;
      flex-direction: column;
      color: #636d70;
      font-family: "Mon-medium", sans-serif;
      font-size: 12px;
      align-items: baseline;
      line-height: 20px;
      label {
        color: #333;
        font-weight: bold;
      }
    }
  }
}
.progress-bar {
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  .cancel-upload {
    position: absolute;
    svg {
      width: 10px !important;
      height: 10px !important;
    }
  }
  .retry-upload {
    position: absolute;
    img {
      width: 12px !important;
      height: 12px !important;
    }
  }
}
