@import "shared/constant/scss/variables.scss";
.my-patient-screen {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#add-patient-container {
  margin: 31px 32px 0px 32px;
  height: fit-content;
  flex-direction: column;
  display: flex;
  .profile-header {
    margin-top: 20px;
    margin-bottom: 10px;
    .header-title {
      font-family: "Mon-medium", sans-serif;
      font-size: 24px;
    }
  }

  .alert-container {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .info-container,
  .alert-container {
    margin-bottom: 20px;
    .info-block {
      display: flex;
      align-items: center;
      .info-line {
        margin-left: 11px;
        font-family: "Mon-medium";
        font-size: 14px;
        line-height: 20px;
        color: #333333;
      }
    }
  }

  .surgeon-episode-date-container {
    margin-bottom: 1.7rem;
  }

  .input-row-container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .input-container {
    width: 33%;
    padding-left: 4px;
    .input-wrapper {
      width: 75%;
      .label {
        font-family: "Mon-medium", sans-serif;
        font-size: 14px;
        margin-top: 0px;
        margin-bottom: 6px;
      }
      .dropdown-label {
        margin-bottom: 6px;
        font-family: "Mon-medium", sans-serif;
        font-size: 14px;
      }
      .text-input-field-container {
        margin-bottom: 25px;
        .field-title {
          margin-top: 0px;
        }
        .field-wrap {
          opacity: 0.8;
          width: auto !important;
          height: 40px;
        }
      }
    }
  }
  .text-area-container {
    width: 58.5%;
    margin-bottom: 24px;
  }
  .tab-section {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .content-area {
    display: grid;
    grid-template-columns: 60% 40%;
    height: 100%;

    .preview-container {
      height: 85%;
      overflow: auto;
    }
    .patient-personal-preview-container {
      height: 75%;
      overflow: auto;
    }
  }
  .patient-input-fields-section {
    height: calc(100vh - 18rem);
    overflow-y: auto;
  }
  .input-fields-section {
    margin-top: 13px;
    height: calc(100vh - 18rem);
    overflow-y: auto;
    #date-picker-container {
      top: -10px !important;
      left: 248px !important;
      right: none !important;
    }
    .input-container {
      .dropdown-container {
        .select-span {
          opacity: 0.8;
        }

        .surgeon-admitting-physician-dropdown,
        .surgeon-practice-dropdown,
        .program-dropdown,
        .episode-dropdown,
        .surgery-site-side-dropdown,
        .acute-care-facility-dropdown,
        .patient-episode-status-dropdown,
        .gender-dropdown .state-dropdown {
          max-height: 225px;
          overflow: auto;
          width: 100%;
        }

        .option-box-wrapper {
          width: 100%;
        }
      }
    }
  }
  .episode-type-radio-input {
    margin-bottom: 25px;
  }
  .episode-setting-radio-input {
  }
  .footer-button-container {
    position: fixed;
    height: 90px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-bottom: 0px;
    background: #ffffff;
    box-shadow: 0px -7px 12px rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  .date-picker-input {
    width: -webkit-fill-available;
  }

  .patient-personal-details-section {
    .emergency-contact-name {
      margin-top: 0px;
    }
    .phone-number-container.emergency-contact-number {
      margin-bottom: 0px;
    }
    .preffered-number-container {
      .phone-number-code {
        height: 40px;
        width: 48px;
        margin-right: 0px;
      }
      .phone-number-input {
        height: 40px;
        width: 100%;
        padding-right: 0px;
        min-width: 0px;
        .field-wrap {
          width: 100%;
          height: 40px;
        }
      }
    }
    .phone-number-container {
      margin-top: 0px;
      .phone-number-code {
        height: 40px;
        width: 48px;
        margin-right: 0px;
      }
      .phone-number-input {
        width: 100%;
        padding-right: 0px;
        min-width: 0px;
        .field-wrap {
          height: 40px;
          width: 100%;
        }
      }
      .phone-number-inputs {
        align-items: start;
      }
    }
  }
  .red-color-asterisk {
    color: red;
  }
  .patient-episode-status-input-disabled {
    font-family: "Mon-medium";
  }
}
