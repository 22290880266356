@import "../../shared/constant/scss/variables.scss";

.pill {
  background-color: $grey-bg-color;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 10px 10px 0px;

  .text {
    font-family: "Mon-medium", sans-serif;
    font-size: 12px;
    margin-right: 8px;
  }

  .close-icon {
    cursor: pointer;
  }
}
