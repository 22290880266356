@import "../../../../shared/constant/scss/variables.scss";
.toc-table {
  .toc-table-header {
    margin-bottom: 10px;
    position: sticky;
    top: 0;
    left: 0;
    background-color: #f7fcfb;
    &::after {
      content: "";
      height: 1px;
      width:100%;
      margin-left: 0.2rem;
      position: absolute;
      left: 0;
      top: 0;
      background: $primary-border-color;
    }
    &-content {
      display: flex;
      align-items: center;
      .name {
        margin-right: 8px;
      }
      &:hover {
        cursor: pointer;
        width: fit-content;
      }
    }
    tr {
      font-family: "Mon-bold", sans-serif;
      color: $black-text-color;
      background: rgba(241, 250, 249, 0.6);
      border-radius: 8px 8px 0px 0px;
      box-shadow: 0 0 0 1px $primary-border-color;
      .column-approved,
      .column-pending {
        font-size: 14px;
        padding: 17px 46px 18px 22px;
        text-align: left;
        font-family: "Mon-bold", sans-serif;
        &:nth-child(1) {
          padding-right: 0px;
          width: 18%;
          & > div {
            width: fit-content;
            &:hover {
              cursor: pointer;
            }
          }
        }
        &:nth-child(2) {
          padding-right: 0px;
          width: 18%;
        }
        &:nth-child(3) {
          padding-right: 0px;
          width: 25%;
        }
        &:nth-child(4) {
          padding-right: 0px;
          width: 16%;
        }
        &:nth-child(5) {
          padding-right: 0px;
          width: 25%;
        }
      }
    }
  }

  .toc-table-body {
    tr {
      cursor: pointer;
      &.night-border {
        border: 2px solid $night-border;
      }
      &.blue-border {
        border: 2px solid $primary-bg-color;
      }
      td {
        font-size: 14px;
        line-height: 16px;
        padding: 17px 46px 18px 22px;
        text-align: left;
        border: 1px solid $grey-border-color;
        border-style: solid none solid none;
        font-family: "Mon-medium", sans-serif;
        &.night-border {
          border-bottom: 2px solid $night-border;
        }
        a {
          color: $primary-text-color;
          cursor: pointer;
        }
        span {
          .bold-text {
            font-weight: bold;
            color: $primary-bg-color;
          }
        }
      }
      td:nth-child(1) {
        border-style: solid none solid solid;
        padding-right: 0px;
        word-break: break-word;

        .patient-detail {
          font-family: "Mon-medium", sans-serif;
          font-size: 14px;
          line-height: 17px;
          color: $primary-text-color;
        }
      }
      td:nth-child(4) {
        .date-time-container {
          display: flex;
          align-items: center;
          .night-icon {
            margin-left: 17.3px;
          }
        }
      }
      td:last-child {
        border-style: solid solid solid none;
        .icons {
          &.visible {
            visibility: visible;
            opacity: 1;
            transition: opacity 0.3s ease-in-out;
          }
          &.hidden {
            visibility: hidden;
            opacity: 0;
            transition: opacity 0.1s ease-in-out;
          }
          .icon-background {
            height: 32px;
            width: 32px;
            background-color: white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &.message {
              margin-left: 14px;
            }
          }
        }
      }
      .missed {
        color: $missed-call-color;
      }
    }
    tr:last-child {
      border-radius: 0px 0px 8px 8px;
      box-shadow: 0 0 0 1px $grey-border-color;
    }
    .hovered {
      background: #eeeeee;
    }
  }
}
