.thumbnail-container {
    position: relative;
    display: flex;
    align-items: center;
    margin: 10px 20px 10px 20px;
    width: 90%;
    .preview-image {
      width: 50px;
      height: 50px;
      padding: 10px 10px 10px 10px;
      border-radius: 10px;
    }
    .thumbnail-right-section {
      width: 88%;
    }
    .cross-icon {
      background: white;
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid grey;
      position: absolute;
      left: 0;
      top: 5px;
      cursor: pointer;
    }
    .name {
      margin-bottom: 4px;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .name,
    .size {
      font-family: "Mon-medium", sans-serif;
      font-size: 12px;
    }
    .size {
      opacity: 0.6;
    }
  }
  