@import "../../shared/constant/scss/variables.scss";

.otpScreenContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .otpFormWrapper {
    display: flex;
    height: inherit;
    width: 40%;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .left-content {
      transform: translateY(-30px);

      .username-icon {
        margin-left: 14px;
      }

      .input-field {
        font-family: "Mon-medium";
      }

      .button-container {
        display: flex;
        justify-content: space-between;

        .send-otp {
          font-family: "Mon-medium", sans-serif;
          font-size: 16px;
          line-height: 20px;
          width: 141px;
          height: 40px;
        }

        .disabled {
          pointer-events: none;
          opacity: 0.5;
        }

        .back-to-login {
          cursor: pointer;
          font-size: 16px;
          line-height: 20px;
          font-family: "Mon-medium", sans-serif;
        }
      }
    }

    .otp-header {
      position: absolute;
      top: 42px;
    }

    .heading {
      display: flex;
      align-items: center;
      margin-bottom: 40px;

      .reset-password-icon-container {
        height: 34px;
        width: 34px;
        background-color: #eaf4e3;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 17px;
      }

      .reset-password-heading {
        font-family: "Mon-semi-bold", sans-serif;
        font-size: 22px;
        line-height: 27px;
        color: #000000;
      }
    }

    .error {
      color: #ff5757;
      font-family: "Mon-medium", sans-serif;
      font-size: 14px;
      line-height: 17px;
    }

    .verified {
      font-family: "Mon-medium", sans-serif;
      font-size: 14px;
      line-height: 17px;
      color: #4cbf07;
    }

    .reset-password-section {
      display: flex;
      flex-direction: column;

      .input {
        margin-left: 0px;
      }

      .confirm-button {
        width: 127px;
        height: 40px;
        align-self: flex-end;
        cursor: pointer;
        font-family: "Mon-medium", sans-serif;
        font-size: 16px;
        line-height: 20px;

        &:disabled {
          opacity: 0.3;
        }
      }
    }

    .validations {
      margin-top: 58px;

      li {
        list-style-image: url("../../shared/assets/images/validation-dot.svg");
        margin: 20px 0px 0px 10px;
        font-family: "Mon-medium", sans-serif;
        font-size: 14px;
        line-height: 17px;
        color: $light-black;

        span {
          position: relative;
          left: 10px;
        }
      }

      .tick {
        list-style-image: url("../../shared/assets/images/validation-tick.svg");
      }
    }

    // .disabled {
    //   pointer-events: none;
    //   opacity: 0.5;
    //   color: rgba(255, 255, 255, 0.35);
    // }
  }

  .rightImageBlock {
    flex: 1;
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: inherit;
    }

    .big-text {
      position: absolute;
      bottom: 80px;
      z-index: 2;

      .line1 {
        font-family: "Mon-light";
        font-size: 34px;
        line-height: 41px;
        letter-spacing: 0.08em;
        color: #e5e5e5;
      }

      .line2 {
        text-align: center;
        font-family: "Mon-bold";
        font-size: 63px;
        line-height: 82px;
        letter-spacing: 0.08em;
        color: #e5e5e5;
      }
    }
  }
}