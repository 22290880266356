@import "shared/constant/scss/variables.scss";

.active-tab-with-top-line {
  display: flex;
  flex-direction: row;
  width: 100%;
  .single-tab {
    display: flex;
    flex-direction: column;
    margin-right: 4px;
    width: 33%;
    .single-tab-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .top-line {
        width: 100%;
        height: 4px;
        background-color: #c1c9d2;
        border-radius: 100px;
      }
      .top-line-active {
        background-color: #1aa99c;
      }
      .container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
        margin-bottom: 12px;
        color: #7e7e7e;
        font-family: "Mon-medium", sans-serif;
        font-size: 14px;
        &.active {
          color: #1aa99c;
        }
        input {
          appearance: none;
          height: 18px;
          width: 18px;
          border: 2px solid #656565;
          border-radius: 50%;
          margin: 0px 10px 0px 0px;
          &:checked {
            background-repeat: no-repeat;
            background-position: center center;
            border: 6px solid #1aa99c;
          }
        }
        .tick-in-black-background {
          margin-right: 8px;
        }
      }
    }
  }
  .active {
  }
}
.pointer{
  cursor: pointer;
}

.non-pointer{
  cursor: default;
}