html,
body,
#root,
.app {
  height: 100%;
}
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
}

p {
  margin: 0;
  padding: 0;
}

table {
  width: 100%;
  margin-top: 16px;
  border-spacing: 0;
  border-collapse: collapse;
}

input,
textarea {
  outline: none;
}

@font-face {
  font-family: "Mon-medium";
  src: local("Mon-medium"),
    url(./shared/assets/fonts/Montserrat-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Mon-semi-bold";
  src: local("Mon-semi-bold"),
    url(./shared/assets/fonts/Montserrat-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Mon-bold";
  src: local("Mon-bold"),
    url(./shared/assets/fonts/Montserrat-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Mon-light";
  src: local("Mon-light"),
    url(./shared/assets/fonts/Montserrat-Light.ttf) format("truetype");
}

b {
  font-family: "Mon-bold", sans-serif;
}

.no-pointer-events {
  pointer-events: none;
  opacity: 0.4;
  cursor: none;
}

:root {
  --toastify-color-success: #1aa99c !important;
  --toastify-color-error: #e17271 !important;
  --toastify-color-warning: #e17271 !important;
}
.Toastify__toast--error {
  background: #fae1e2 !important;
}
.Toastify__toast--success {
  background: #f0f9fa !important;
}
.Toastify__toast--warning {
  border: 1px solid #e78326 !important;
  background: #fadfc5 !important;
}
.Toastify__toast-body {
  color: #444c63;
  padding: 10px 10px 10px 10px !important;
  font-size: 15px;
  width: 100%;
}
.Toastify__close-button {
  align-self: auto;
  margin: 10px;
}
.Toastify__toast-icon {
  margin-right: 20px !important;
}
.Toastify__toast-container {
  padding: 10px 10px 10px 10px !important;
  width: max-content !important;
  max-width: 450px;
  min-width: 350px;
}
