.patient-personal-details-section {
  .dropdown-container {
    .gender-dropdown {
      width: 298px;
      margin-top: 8px;
    }

    .label {
      margin-top: 0px;
      margin-bottom: 6px;
    }

    .select-span {
      height: 40px;
    }
  }
  .emergency-contact-name {
    margin-top: 30px;
  }
  .note {
    color: #7e7e7e;
    font-style: italic;
    font-family: "Mon-medium", sans-serif;
    font-size: 14px;
  }
  .label {
    font-family: "Mon-medium", sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
    margin: 6px 0px;
    .red-color {
      margin-left: 5px;
      color: red;
    }
  }

  .phone-number-container,
  .preffered-number-container {
    margin-bottom: 25px;
    .phone-number-inputs {
      display: flex;
      align-items: center;
    }
    .phone-number-code {
      width: 56px;
      height: 48px;
      background: #ffffff;
      mix-blend-mode: normal;
      opacity: 0.8;
      border: 1px solid #c1c9d2;
      box-sizing: border-box;
      border-radius: 8px;
      margin-right: 8px;
      text-align: center;
    }
    .phone-number-input {
      min-width: 234px;
      height: 48px;
      background: #ffffff;
      mix-blend-mode: normal;
      opacity: 0.8;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 0px 10px 0px 10px;
    }
  }
  .prefferd-calling-radio-inputs {
    font-family: "Mon-medium", sans-serif;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    height: 40px;
    align-items: center;

    .preferred-calling-number {
      font-family: "Mon-medium", sans-serif;
      font-size: 14px;
      line-height: 17px;
      color: #7e7e7e;
      display: flex;
      width: 50%;
      input[type="radio"] {
        cursor: pointer;
        appearance: none;
        height: 18px;
        width: 18px;
        border: 2px solid #656565;
        border-radius: 50%;
        margin: 0px 10px 0px 0px;
        &:checked {
          background-image: url("../../../../shared/assets/images/radio-input-checked.svg");
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
    }
  }
}
