.common-table-listing-container {
  height: 100%;
  min-width: 40rem;
  .common-listing-table {
    display: flex;
    flex-direction: column;
    height: 100%;
    .common-table-listing-header {
      display: flex;
      flex-direction: row;
      width: 100%;
      background: #f1faf999;
      border-radius: 8px 8px 0 0;
      box-shadow: 0 0 0 1px #bcdfef;
      color: #333;
      font-weight: 700;
      z-index: 1;
      .column {
        display: flex;
        width: 14%;
        box-sizing: border-box;
        padding: 17px 0 18px 22px;
        align-items: center;
        box-sizing: border-box;
        &:nth-child(2) {
          width: 10%;
        }
        &:nth-child(3) {
          width: 23%;
        }
        &:nth-child(4) {
          padding-right: 0px;
          width: 10%;
        }
        &:last-child {
          width: 15%;
          padding-right: 1rem;
        }

        .column-content {
          display: flex;
          align-items: center;
          cursor: pointer;
          .content {
            margin-right: 8px;
            font-family: Mon-bold, sans-serif;
            font-size: 14px;
          }
          .sort-icon {
            &:hover {
              cursor: pointer;
              background: #f1faf9;
              border-radius: 8px;
            }
          }
        }
      }
    }
    .common-table-listing-content {
      background-color: #fff;
      box-sizing: border-box;
      display: block;
      width: 100%;
      border: 1px solid #eef0f6;
      border-radius: 8px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      #scroll {
        margin-top: 0;
      }
      & > .infinite-scroll-component__outerdiv > .infinite-scroll-component {
        overflow: overlay !important;
      }
      .common-table-listing-row {
        display: flex;
        flex-direction: row;
        box-shadow: 0 1px 0 0 #eef0f6;
        position: relative;
        .column {
          width: 14%;
          padding: 17px 46px 18px 22px;
          box-sizing: border-box;
          &:first-child {
            padding: 17px 0 18px 22px;
          }
          &:nth-child(2),
          &:nth-child(4) {
            width: 10%;
          }
          &:last-child {
            width: 15%;
          }
          &:nth-child(3) {
            width: 23%;
          }
          .content {
            height: 95%;
            display: flex;
            align-items: center;
            justify-content: start;
            font-family: "Mon-medium", sans-serif;
            font-size: 14px;
            line-height: 16px;
          }
        }
        &:hover {
          background: #eeeeee;
          cursor: pointer;
        }
      }
    }
    .no-message-container {
      display: flex;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      font-family: "Mon-medium", sans-serif;
      font-size: 14px;
      padding: 17px 0 18px 0;
      padding-left: 24px;
      &:hover {
        background: #fff !important;
        cursor: default !important;
      }
    }
  }
}
