@import "shared/constant/scss/variables.scss";

#toc-detail {
  padding: 28px 44px;
  width: 100%;
  height: 93%;
  background-color: white;
  .toc-detail-presentation {
    overflow: auto;
    width: 100%;
    height: inherit;
    padding: 1px 1px 1px 1px;
  }
  .bread-crumb {
    margin-bottom: 29.5px;
  }
  .toc-detail-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 20px;
    .heading {
      font-family: "Mon-bold", sans-serif;
      font-size: 24px;
      line-height: 29px;
      color: #333333;
    }
    .age-gender {
      font-family: "Mon-medium", sans-serif;
      font-size: 14px;
      line-height: 17px;
      color: #636d70;
      margin-top: 10px;
    }
  }
  .more-details {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    .info {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 12px;
      .row {
        margin-right: 20px;
        font-family: "Mon-semi-bold", sans-serif;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        width: 143px;
        display: flex;
        justify-content: flex-end;
      }
      .val {
        font-family: "Mon-medium", sans-serif;
        font-size: 14px;
        line-height: 17px;
        color: #333333;
        word-break: break-word;
        width: 85%;
      }
    }
  }
  .revisons {
    .heading {
      font-family: "Mon-bold", sans-serif;
      font-size: 20px;
      line-height: 24px;
      color: #64666a;
      margin-bottom: 8px;
      margin-top: 28px;
    }

    .revision-table {
      &-container {
        .toc-revision-default-width {
          min-width: unset !important;
          width: 45% !important;
          margin-left: 0rem;
        }
        &.task-info-tab-visible {
          .note-episode-container {
            width: 100%;
            margin-right: 2rem;
            margin-left: 2rem;
          }
          .location-grid-container {
            width: 100%;
            margin-right: 2rem;
            margin-left: 2rem;
          }
        }
      }
      .revision-table-header {
        display: flex;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 1;
        text-align: left;
        font-size: 14px;
        padding: 15px;
        border: 1px solid $primary-border-color;
        font-family: "Mon-bold", sans-serif;

        font-weight: bold;
        color: #333;
        background: rgb(241, 250, 249);
        border-radius: 8px 8px 0 0;
        border-style: hidden;
        box-shadow: 0 0 0 1px $primary-border-color;

        .revision-table-header-cell {
          &:nth-child(1) {
            width: 20%;
          }
          &:nth-child(2) {
            padding-right: 0px;
            width: 20%;
          }
          &:nth-child(3) {
            padding-right: 0px;
            width: 15%;
          }
          &:nth-child(4) {
            padding: 0px;
            width: 20%;
          }
          &:nth-child(5) {
            border-radius: 0 8px 0 0;
            padding-right: 0px;
            width: auto;
          }
        }
      }

      .revision-table-body {
        background-color: #fff;
        box-sizing: border-box;
        display: block;
        height: 100%;
        width: 100%;
        border: 1px solid #eef0f6;
        border-radius: 8px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        td {
          text-align: left;
          padding: 1.5rem;
          &:nth-child(1) {
            width: 20%;
          }
          &:nth-child(2) {
            padding-right: 0px;
            width: 20%;
          }
          &:nth-child(3) {
            padding-right: 0px;
            width: 15%;
          }
          &:nth-child(4) {
            padding: 0px;
            width: 20%;
          }
          &:nth-child(5) {
            padding-right: 0px;
            width: auto;
          }
        }
      }
    }
  }
}
