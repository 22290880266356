@import "../../../shared/constant/scss/variables.scss";

#modal {
  .filter-side-popup {
    .filter-content-container {
      .filter-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 85%;
        padding: 25px 19px 0px 13px;
        .surgery-sub-heading {
          color: $filter-sub-heading-color;
          font-family: "Mon-semi-bold", sans-serif;
          font-size: 12px;
          line-height: 15px;
          margin-bottom: 6px;
        }
        .surgery-date-to {
          margin-top: 30px;
        }
        .filter-buttons {
          display: flex;
          flex-direction: column;
          margin-bottom: 24px;

          .apply-button {
            margin-bottom: 16px;

            &.disabled {
                opacity: 0.4;
            }
            &.enabled {
                opacity: 1;
            }
          }

          .clear-button {

            &.disabled {
                opacity: 0.4;
                pointer-events: none;
            }
            &.enabled {
                opacity: 1;
            }
          }

          input {
            width: 95%;
          }

          .apply-button,
          .clear-button {
            width: 100%;
            height: 40px;
            font-family: "Mon-medium", sans-serif;
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
