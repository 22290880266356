#scroll {
  height: 100%;
  overflow: auto;
  display: flex;
  margin-top: 9px;

  .infinite-scroll-component__outerdiv {
    width: 100%;
  }
}
