.empty-state-container {
  height: 90%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loader {
  margin: auto;

  img {
    width: 100px;
  }
}

.button-text {
  font-family: "Mon-bold", sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: rgba(51, 51, 51, 0.8);
  margin-top: 21px;
  margin-bottom: 30px;
}

.back-button {
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  font-family: "Mon-medium", sans-serif;
}

.no-toc-found {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-heading,
  &-text {
    font-size: 14px;

    font-family: "Mon-medium", sans-serif;
  }
  &-heading {
    font-family: "Mon-bold", sans-serif;
    margin-top: 37.6px;
    margin-bottom: 8px;
  }
  img {
    width: 214.182px;
    height: 193.394px;
  }
}

.no-approved-toc-found {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-heading,
  &-text {
    font-size: 13px;
    color: #636d70;
    line-height: 151.1%; /* 30.22px */
    font-family: "Mon-medium", sans-serif;
  }
  &-heading {
    color: #000;
    font-size: 20px;
    text-align: center;

    margin-top: 18.61px;
  }
  img {
    width: 186.974px;
    height: 193.39px;
  }
}
