$grey-text-color: #7e7e7e;
$error-color: #ff5757;
$primary-bg-color: #1aa99c;
$white-bg-color: white;
$primary-text-color: #00887c;
$primary-border-color: #bcdfef;
$grey-border-color: #eef0f6;
$grey-header-color: #64666a;
$grey-sub-header-color: #687170;
$missed-call-color: #b91d1d;
$white: #ffffff;
$grey-bg-color: #eeeeee;
$light-green-color: #f1faf9;
$red-notification-color: #ff6136;
$light-green-color-2: #e3f3ef;
$black-text-color: #333333;
$light-black: #707070;
$search-color: #636d70;
$filter-sub-heading-color: #6b727f;
$primary-input-border: #c1c9d2;
$placeholder-text-color: #c1c1c1;
// border
$night-border: rgba(30, 0, 148, 0.3);
