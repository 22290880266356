.avatar-container {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid gray;
  position: relative;
  margin-bottom: 24px;
  cursor: pointer;
}
