@import "../../shared/constant/scss/variables.scss";

.search-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff;
  mix-blend-mode: normal;
  border: 1px solid #a6d9ce;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px;
  margin-right: 16px;
  transition: width 0.5s, opacity 0.5s ease-in-out;

  .input-plus-lens {
    margin: auto 0;
    position: relative;
    width: 80%;
    .search-lens-icon {
      margin-right: 1.2rem;
      transform: translateY(0.2rem);
    }
    .input-field {
      border: none;
      -webkit-appearance: none;
      outline: none;
      padding-right: 4px;
      z-index: 2;
      font-family: "Mon-medium", sans-serif;
      font-size: 14px;
      color: $search-color;
      text-transform: lowercase;
      width: 100%;
      &::placeholder {
        font-family: "Mon-medium", sans-serif;
        font-size: 14px;
        color: $placeholder-text-color;
        word-break: break-word;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .suggestion {
      z-index: 1;
      opacity: 0.6;
      position: absolute;
      top: 1px;
      left: 1px;
      width: 100%;
      height: 100%;
      pointer-events: none;
      font-family: "Mon-medium", sans-serif;
      font-size: 14px;
      color: $search-color;
      text-transform: lowercase;
    }
    // .placeholder {
    //   z-index: 1;
    //   opacity: 0.6;
    //   position: absolute;
    //   top: 1px;
    //   left: 1px;
    //   width: 100%;
    //   height: 100%;
    //   pointer-events: none;
    //   font-family: "Mon-medium", sans-serif;
    //   font-size: 14px;
    //   color: $search-color;
    //   word-break: break-word;
    //   text-overflow: ellipsis;
    //   white-space: nowrap;
    //   overflow: hidden;
    // }
  }
  .icon-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 3px;
  }
}

.green-button {
  background: $primary-bg-color;
  border-radius: 8px;
  border: none;
  color: white;
}

.white-button {
  background: $white-bg-color;
  border: 1px solid hsl(165, 14%, 71%);
  border-radius: 8px;
}

.icon-button {
  cursor: pointer;
}
