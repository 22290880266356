@import "../../shared/constant/scss/variables.scss";
.otpScreenContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .otpFormWrapper {
    display: flex;
    height: inherit;
    width: 40%;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .left-content {
      transform: translateY(-30px);
    }

    .otp-header {
      position: absolute;
      top: 42px;
      .logo {
        width: 40px;
        height: 40px;
      }
    }

    .heading {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      .reset-password-icon-container {
        height: 34px;
        width: 34px;
        background-color: #eaf4e3;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 17px;
      }
      .reset-password-heading {
        font-family: "Mon-semi-bold", sans-serif;
        font-size: 22px;
        line-height: 27px;
        color: #000000;
      }
    }

    .otpBoxes {
      margin-bottom: 8px;
      .otp-line {
        font-size: 14px;
        line-height: 17px;
        font-family: "Mon-medium", sans-serif;
        margin-bottom: 10px;
      }

      .username-icon {
        margin-left: 14.25px;
        margin-right: 12.25px;
      }
      .inputStyling {
        width: 76px !important;
        height: 76px !important;
        margin-right: 8px;
        background-color: transparent;
        border: 1px solid #cdd4db;
        border-radius: 12px;
        font-size: 36px;
        color: #000000;

        &::placeholder {
          transform: translateY(-4px);
        }
        &:focus {
          border: 1px solid #5652ff;
        }
        &.error {
          border: 1px solid #ff5757;
        }
        &.verified {
          border: 1px solid #4cbf07;
        }
      }
    }
    .timeRemainingDiv {
      .timeRemainingSpan {
        font-family: "Mon-medium", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #8c8c8c;
        .seconds {
          font-family: "Mon-bold", sans-serif;
          color: black;
        }
      }
    }

    .error {
      color: #ff5757;
      font-family: "Mon-medium", sans-serif;
      font-size: 14px;
      line-height: 17px;
    }

    .verified {
      font-family: "Mon-medium", sans-serif;
      font-size: 14px;
      line-height: 17px;
      color: #4cbf07;
    }
    .reset-password-section {
      display: flex;
      flex-direction: column;
      .input {
        margin-left: 0px;
      }
      .confirm-button {
        width: 127px;
        height: 40px;
        align-self: flex-end;
        cursor: pointer;
        font-family: "Mon-medium", sans-serif;
        font-size: 16px;
        line-height: 20px;

        &:disabled {
          opacity: 0.3;
        }
      }
    }

    .validations {
      margin-top: 58px;
      li {
        list-style-image: url("../../shared/assets/images/validation-dot.svg");
        margin: 20px 0px 0px 10px;
        font-family: "Mon-medium", sans-serif;
        font-size: 14px;
        line-height: 17px;
        color: $light-black;

        span {
          position: relative;
          left: 10px;
        }
      }

      .tick {
        list-style-image: url("../../shared/assets/images/validation-tick.svg");
      }
    }

    .button-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .button-container {
        .resend-otp {
          font-family: "Mon-medium", sans-serif;
          font-size: 14px;
          line-height: 17px;
          color: $primary-bg-color;
          padding: 0px;
          cursor: pointer;
        }
      }
      .disabled {
        pointer-events: none;
        opacity: 0.5;
        color: rgba(255, 255, 255, 0.35);
      }
    }
  }
  .rightImageBlock {
    flex: 1;
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      height: inherit;
    }
    .big-text {
      position: absolute;
      bottom: 80px;
      z-index: 2;
      .line1 {
        font-family: "Mon-light";
        font-size: 34px;
        line-height: 41px;
        letter-spacing: 0.08em;
        color: #e5e5e5;
      }
      .line2 {
        text-align: center;
        font-family: "Mon-bold";
        font-size: 63px;
        line-height: 82px;
        letter-spacing: 0.08em;
        color: #e5e5e5;
      }
    }
  }
}
