@import "../../shared/constant/scss/variables.scss";
#single-date-picker-selector {
  position: relative;
  .date-picker-container {
    position: relative;
    display: inline-block;
    z-index: 2;
  }
  .red-color {
    font-family: "Mon-medium", sans-serif;
    color: #ff5757;
    font-size: 12px;
    margin: 6px 0;
    mix-blend-mode: normal;
    position: static;
    outline: $primary-input-border;
  }
  .calender-icon {
    display: block !important;
    position: absolute;
    right: 10px;
    top: 23px;
    transform: translateY(-50%);
    &:hover {
      cursor: pointer;
    }
  }
  .react-datepicker__close-icon {
    right: 5px;
  }
  .date-picker-input {
    padding: 14px 16px 10px 12px;
    border: 1px solid $primary-input-border;
    flex: none;
    border-radius: 8px;
    box-sizing: border-box;
    &.red-border {
      border: 1px solid red;
    }
    &::placeholder {
      font-family: "Mon-medium", sans-serif;
      font-size: 14px;
      line-height: 17px;
      color: $placeholder-text-color;
      font-weight: 400 !important;
    }
  }
  &.disabled-date-selector {
    cursor: not-allowed;
    button {
      display: none;
    }
  }
  .date-picker-input:disabled {
    opacity: 0.4;
  }

  .react-datepicker__close-icon {
    right: 5px;
  }

  .date-picker-calender {
    font-family: "Mon-semi-bold", sans-serif;
    padding: 22px 0 0 0;
    border: 1px solid rgba(51, 51, 51, 0.2);
    border-radius: 8px;
    .react-datepicker__day-names {
      position: relative;
      padding: 0 3px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .react-datepicker__header {
      background-color: #ffffff;
      border: none;

      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header {
        font-size: 16px;
        color: #6b727f;
      }
    }

    .react-datepicker__day--disabled {
      opacity: 0.4 !important;
      cursor: not-allowed;
    }
    .react-datepicker__day--weekend {
    }

    .react-datepicker__navigation {
      top: 21px;
    }

    .react-datepicker__day--keyboard-selected {
      background-color: #ffffff;
    }
  }

  .react-datepicker__navigation-icon::before {
    border-color: #4f4f4f;
    border-width: 2px 2px 0 0;
  }

  .react-datepicker__day,
  .react-datepicker__day-name {
    width: 27px;
  }
  .date-picker-day {
    font-family: "Mon-semi-bold", sans-serif;
    font-size: 16px;
    line-height: 20px;
    color: #6b727f;
    width: 48px;
    line-height: 46px;
    margin: auto;

    &.react-datepicker__day--selected {
      background-color: #00887c;
      color: #ffffff;
    }
  }
  .date-picker-week-day {
    font-family: "Mon-semi-bold", sans-serif;
    font-size: 12px;
    line-height: 15px;
    color: #6b727f;
    margin-top: 29px;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
}
