.preferred {
  border-radius: 2px;
  background-color: #00887c;
  padding: 2px 4px;
  color: #fff;
  font-family: "Mon-bold", sans-serif;
  font-size: 8px;
  height: 10px;
  display: flex;
  align-items: center;
}
