#note-container {
  display: flex;
  background: #70a39f;
  padding: 4px 12px;
  column-gap: 10px;

  &.note-listing {
    flex-direction: column;
    row-gap: 5px;
    ul {
      list-style-type: disc;
      margin-left: 20px;
    }
  }

  .title {
    font-family: "Mon-bold", sans-serif;
    font-size: 12px;
    font-style: italic;
    line-height: 140%;
    color: #fff;
  }

  .description-text {
    color: #fff;
    font-family: "Mon-medium", sans-serif;
    font-size: 12px;
    font-style: italic;
    line-height: 140%;
  }
}
