@import "../../shared/constant/scss/variables.scss";

.green-button {
  background: $primary-bg-color;
  border-radius: 8px;
  border: none;
  color: white;
  cursor: pointer;
}

.white-button {
  background: $white-bg-color;
  border: 1px solid hsl(165, 14%, 71%);
  border-radius: 8px;
  cursor: pointer;
}

.no-border-button {
  border: none;
  outline: none;
  background: transparent;
  color: $primary-bg-color;
}

.icon-button {
  cursor: pointer;
}

.img-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    margin-left: 12px;
  }
}
